<template>
  <div style="width: 100%; height: 100%; padding: 20px; box-sizing: border-box">
    <div class="cert-container pdf-screen" ref="print" id="print">
      <img src="../../assets/cert_bg.jpg" crossorigin="anonymous" class="cert-bg" />
      <!--student name-->
      <div class="studentName">
        {{ studentName }}
      </div>
      <div class="entry">
        {{ entryTitle }}
      </div>
      <div class="awardName" id="awardName">
        {{ showAwardName }}
      </div>

      <div class="project_code">
        {{ showProjectName }}
      </div>

      <div class="entryType">Entry Type: <span style="color: #333333;">{{ entryType }} | </span>{{ "Category: "  }} <span style="color: #333333;">{{ categoryName }}</span>
      </div>

      <div class="subcategory">
        {{ "Subcategory: "  }} <span style="color:#333333;"> {{ subCategory }}</span>
      </div>

      <div class="student_describe">
        {{ student_describe  }}
      </div>


      <div class="certDate" >
        <div style="margin-top: 5px;" v-html="showCertDate"></div>
      </div>
      <div class="app_id" >
        Application ID: {{ application_id }}
      </div>
    </div>

    <el-button type="primary" size="small" style="margin-bottom: 20px; position: absolute" @click="downloadPdf">
      Download
    </el-button>
  </div>
</template>

<script>
import "../../assets/common/font.css";
import { getEntryMemberEntry } from "../../api/index";
import {
  getUserId
} from '../../utils/store'
export default {
  name: "index",
  data() {
    return {
      entry_id: this.$route.query.id,
      studentName: "",
      entryTitle: "",
      categoryName: "",
      subCategory: "",
      entryType: "",
      showAwardName: "",
      showCertDate: "",
      hasAward: false,
      project_code: "",
      team_type: '',
      tags: '',
      showProjectName: '',
      application_id:'',
      student_describe: ''
    };
  },
  mounted() {
    if (false) {
      this.entry_id = "65fe8f96a4640bc60d8b45ab";
    }
    //传递时间戳
    this.fetchData();
  },
  methods: {
    fetchData() {
      getEntryMemberEntry(this.entry_id).then((res) => {
        let result = res.data.data;
        this.application_id = result.application._id.$id
    

        if (result.student.student_lastName_pinyin) {
          this.studentName =
            result.student.student_givenName_pinyin +
            " " +
            result.student.student_lastName_pinyin;
        } else {
          this.studentName =
            result.student.student_lastName +
            " " +
            result.student.student_givenName;
        }
        this.entryTitle = result.title;
        this.categoryName = result.category;
        this.project_code = result.project_manage.project_code
        this.showProjectName = result.project_name
        this.subCategory = result.subcategory;

        if (result.entry_type == 'individual') {
          this.entryType = 'Individual'
        } else {
          this.entryType = 'Team'
          this.student_describe = result.student_describe
        }
        let cert_date = ''
        let entry_date = result.project_manage.entry_date
        for (let i = 0; i < entry_date.length; i++) {
          let item = entry_date[i]
          if (item.app_type == 'indie' && result.entry_type == 'individual') {
            cert_date = item.cert_date
            break
          }
          if (item.app_type == 'team' && result.entry_type == 'team') {
            cert_date = item.cert_date
            break
          }
        }
        this.formatCertDate(cert_date)

        this.$nextTick(() => {
          this.calcScore(result);
        })
      });
    },

    formatCertDate(publish_time) {
      let publishTime = publish_time;
      var date = new Date(publishTime);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      this.showCertDate =
        this.formatMonthToEng(month) + " " + day + "<br> <span style=color:#7382A3;>"+year+"</span>";
    },
    formatShowEntryType(type) {
      switch (type) {
        case "team_exp":
          this.entryType = "Team Work & Experience";
          break;
        case "team_story":
          this.entryType = "Team Storyline";
          break;
        case "indie_story":
          this.entryType = "Individual Storyline";
          break;
        case "indie_exp":
          this.entryType = "Individual Work & Experience";
          break;
      }
    },
    calcScore(entry) {

      if (entry.eae_entry.score) {
        let entryScore = 0;
        let bonus_points = entry.eae_entry.score.bonus_points;
        let scoring_dimension = entry.eae_entry.score.scoring_dimension;
        let keys = Object.keys(scoring_dimension);
        for (let i = 0; i < keys.length; i++) {
          let score = scoring_dimension[keys[i]];
          entryScore = entryScore + parseInt(score);
        }
        let totalScore = entryScore + bonus_points;
        if (totalScore >= 90) {
          this.hasAward = true;
          this.showAwardName = "Platinum Award";
        } else if (totalScore >= 80) {
          this.hasAward = true;
          this.showAwardName = "Gold Award";
        } else if (totalScore >= 65) {
          this.hasAward = true;
          this.showAwardName = "Silver Award";
        } else if (totalScore >= 50) {
          this.hasAward = true;
          this.showAwardName = "Bronze Award";
        } else {
          this.hasAward = false;
          this.showAwardName = "";
        }
      } else {
        this.hasAward = false;
        this.showAwardName = "";
      }
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "Jan.";
        case 2:
          return "Feb.";
        case 3:
          return "Mar.";
        case 4:
          return "Apr.";
        case 5:
          return "May";
        case 6:
          return "Jun.";
        case 7:
          return "Jul.";
        case 8:
          return "Aug.";
        case 9:
          return "Sept.";
        case 10:
          return "Oct.";
        case 11:
          return "Nov.";
        case 12:
          return "Dec.";
      }
    },
    downloadPdf() {
      this.$pdf(this.$refs.print, {
        name: new Date().getTime(),
        scale: 2,
        ignoreElements: (element) => {
          if (element.className.indexOf("el-button") !== -1) {
            return true;
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.project_code {
  position: absolute;
  text-align: center;
  color: #103675;
  width: 100%;
  margin-top: 460px;
  font-size: 18px;
  font-family: GravityBold;
}

.studentName {
  position: absolute;
  color: #0f3574;
  width: 100%;
  text-align: center;
  margin-top: 250px;
  font-weight: bold;
  font-size: 37px;
  font-family: GravityNormal;
}

.entry {
  position: absolute;
  color: #103677;
  width: 100%;
  text-align: center;
  margin-top: 335px;
  font-size: 18px;
  font-family: GravityBold;
}

.awardName {
  position: absolute;
  color: #D54745;
  width: 100%;
  margin-top: 395px;
  text-align: center;
  font-size: 28px;
  font-family: GravityBold;
}

.category {
  position: absolute;
  color: #333333;
  width: 100%;
  text-align: center;
  margin-top: 464px;
  font-size: 18px;
  font-family: GravityNormal;
}

.subcategory {
  position: absolute;
  text-align: center;
  color: #333333;
  width: 100%;
  margin-top: 550px;
  font-size: 14px;
  font-family: GravityNormal;
}
.student_describe{
  position: absolute;
  text-align: center;
  color: #313131;
  width: 100%;
  line-height: 16px;
  margin-top: 575px;
  font-size: 12px;
  font-family: GravityNormal;
}

.entryType {
  position: absolute;
  text-align: center;
  color: #323232;
  width: 100%;
  margin-top: 525px;
  font-size: 14px;
  font-family: GravityNormal;
}

.certDate {
  display: inline-block;
  position: absolute;
  left: 50px;
  bottom: 96px;
  width: 90px;
  text-align: center;
  height: 58px;
  color: white;
  font-size: 16px;
  line-height: 22px;
  background-color: #103675;
  font-family: GravityBold;
}
.app_id {
  position: absolute;
  left: 50px;
  bottom: 40px;
  color: #909090;
  font-size: 14px;
}

.cert-container {
  position: absolute;
  -webkit-text-size-adjust: 100% !important;
  font-variant: normal;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 620px;
  height: 877px;
}

.cert-bg {
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>